/* @import url('https://fonts.googleapis.com/css2?family=Alatsi&display=swap'); */

/* .toggle_button {
    display: none !important;
}
.toggle_button i {
    line-height: 33px !important;
}
@media (max-width: 1024px) {
    .toggle_button {
        display: block !important;
    }
} */
.side_wrapper li a {
  /* justify-content: center !important; */
  font-weight: 600 !important;
  color: #000 !important;
  font-size: 16px !important;
}

.side_wrapper li {
  margin: 0 0 10px 0;
}

.side_header h6 {
  font-family: 'Nunito', sans-serif;

  color: #000 !important;
  margin: 0 !important;
  font-size: 22px !important;
  font-weight: 400 !important;
  text-transform: none !important;
}
/* 
.page-wrapper .sidebar-wrapper .sidebar-menu {
  padding: 20px 40px !important;
} */

.page-wrapper.landrick-theme li.active,
.page-wrapper.landrick-theme li.active a {
  /* background-color: #56aaaa !important; */
  border-radius: 10px;
  color: #56aaaa !important;
}

.profile-card-parent .ant-card-body {
  padding: 0px !important;
}

.header_extra_buttons:focus,
.header_extra_buttons:active {
  background: #fdc011 !important;
  border-color: #fdc011 !important;
  color: black !important;
}

.page-wrapper.landrick-theme .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  margin: 0;
  padding-left: 22px;
}

.page-wrapper.landrick-theme li:not(.active):hover {
  color: black !important;
}

.minimizedSideBarFirst:hover {
  filter: none !important;
}

:where(.css-dev-only-do-not-override-1pz7qqz).ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover,
:where(.css-dev-only-do-not-override-1pz7qqz).ant-menu-light > .ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover {
  background-color: transparent;
}

.avatarMenuItem {
  border-radius: 0px !important;
}

.avatarMenuItem:hover {
  background: white !important;
  opacity: 1 !important;
}

.userProfileParentCard.ant-card .ant-card-head {
  border: none !important;
}

.userProfileParentCard .ant-card-body {
  width: 100%;
  padding: 0px;
}

.reportPropertyCard > .ant-card-body {
  padding-top: 0px !important;
}

.twoFAHeaderSpan {
  font-size: 14px;
  line-height: 1;
}

.twoFATextSpan {
  font-size: 12px;
}

.cursor-default {
  cursor: default;
}

.otpInputModal .ant-form-item .ant-form-item-explain-error {
  text-align: center;
  margin-top: 5px;
}

.customInputPhone .ant-input-group-wrapper-outlined .ant-input-group-addon:first-child {
  width: 20%;
}

.customInputPhone .ant-input-group .ant-input-group-addon .ant-select {
  width: 100% !important;
}

.customInputPhone .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
}

.otpInputModal .ant-form-item .ant-form-item-control-input-content {
  display: flex;
  justify-content: center;
  width: 100%;
}

.otpInputDiv .ant-form-item .ant-form-item-explain-error {
  margin-top: 5px;
  text-align: center !important;
}

.disabledButton {
  background-color: #f5f5f5 !important;
  color: #bababa !important;
  border-color: #f2f8f8 !important;
}

.twoFAButton {
  width: 120px;
  height: 48px;
  font-weight: 600;
}

.profile-content-card .ant-card-head {
  padding: 0;
  margin: 0;
}

.profile-content-card .ant-card-head-title,
.profile-item-heading {
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.profile-menu .ant-menu-light.ant-menu-root.ant-menu-inline,
.profile-menu .ant-menu-light > .ant-menu.ant-menu-root.ant-menu-inline,
.profile-menu.ant-menu-light.ant-menu-root.ant-menu-vertical,
.profile-menu .ant-menu-light > .ant-menu.ant-menu-root.ant-menu-vertical {
  height: 100%;
}

.profile-menu li span {
  font-size: 16px;
  font-weight: 600;
}

.profile-menu li.ant-menu-item-selected span {
  color: #44aaa9 !important;
}

.userPreviewDetails td {
  border: none;
}

.installAuthenticatorText {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #292d34 !important;
}

.page-wrapper.landrick-theme li a {
  font-weight: 600 !important;
  font-size: 16px !important;
  color: black;
  border-radius: 10px;
}

.page-wrapper.landrick-theme .subMenuTaxAccountant li a {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.subMenuTaxAccountant li {
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-bottom: 5px !important;
}

.page-wrapper.landrick-theme li {
  margin-bottom: 10px;
}

.page-wrapper.landrick-theme li.active > span > a {
  color: black !important;
}

.page-wrapper.landrick-theme li.active img {
  filter: brightness(1) invert(0.6) sepia(0.6) hue-rotate(132deg) saturate(200%) !important;
}

.page-wrapper.landrick-theme li:hover > a {
  color: none !important;
}

/* .siderOverlayMenu .ant-menu-light .ant-menu-item,
.ant-menu-light > .ant-menu .ant-menu-item {
  background: white !important;
}

.siderOverlayMenu .ant-menu-submenu .ant-menu-light .ant-menu-item,
.ant-menu-light > .ant-menu .ant-menu-item.menuReferralCode {
  background: #f2f8f8 !important;
  color: black !important;
} */

.ant-table-thead .ant-table-cell {
  text-align: center !important;
}

.minimizedSideBarFirst {
  scale: 1.3;
}

.card-text.dashboard-card-text {
  font-size: 16px !important;
}

.minimizedSideBarFirst.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):active {
  background-color: #56aaaa !important;
}

.minimizedSideBarFirst img {
  filter: invert(1) brightness(1) !important;
}

.minimizedSideBarFirst:hover {
  filter: invert(1) brightness(1);
}

.minimizedSideBarFirst:hover img {
  filter: none !important;
}

.menuReferralCode {
  background: #f2f8f8 !important;
}

.menuReferralCode:hover {
  color: black !important;
}

.disabledButton {
  background-color: #f5f5f5 !important;
  color: #bababa !important;
  border-color: #f2f8f8 !important;
}

.userSiderMenuIcon {
  filter: invert(1) brightness(1);
}

.sidebar-wrapper li.active {
  background-color: #f2f8f8 !important;
}

.minimizedSideBarFirst:hover .userSiderMenuIcon {
  filter: none;
}

.sidebar-wrapper.sidebarNavigationParent .active a span {
  filter: none !important;
}

.active .userSiderMenuIcon span {
  filter: none !important;
}

.tooltipChildCss {
  margin: 0px;
  padding-left: 20px;
}

.btn {
  font-size: 16px !important;
  color: #000 !important;
  font-weight: 600 !important;
}

/* form input and select */
label {
  font-size: 16px !important;
}

input::placeholder {
  font-size: 14px !important;
}

select {
  font-size: 14px !important;
}

/* form input and select */
/************************************ dashboard convert horizontal start ************************************/
.page-wrapper.toggled .header-top1 {
  left: 0px !important;
  background-color: #fff;
}

@media screen and (min-width: 1025px) {
  .page-wrapper.toggled .page-content1 {
    padding-left: 0px !important;
  }
}

.page-wrapper .page-content .header-top1 .header-bar1 {
  box-shadow: 0 0 0px rgb(60 72 88 / 15%) !important;
}

.nav_link a {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

.search-bar .active a {
  color: #56aaaa;
  font-size: 16px;
  font-weight: 600;
}

.nav_link a:hover {
  color: #56aaaa;
  font-size: 16px;
  font-weight: 600;
}

/* .nav_link:before {
  content: "";
  position: absolute !important;
  border: 2px solid #fff !important;
  width: 0px !important;
  height: 5px;
  background-color: #fff !important;
  bottom: 15px;
  transition: all 0.5s ease-in-out;
} */
/* 
.page-wrapper .nav_link.active::before {
  content: "";
  position: absolute !important;
  border: 2px solid #000 !important;
  width: 34px !important;
  height: 5px;
  background-color: #000 !important;
  bottom: 15px;
  transition: all 0.5s ease-in-out;

} */

/************************************ dashboard convert horizontal end ************************************/

/******************************************************************* tax accountant  home page start *******************************************************************/
table {
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #0000001a;
}

.dashboard_list .card {
  border: 1px solid #0000001a;
  border-radius: 11px;
}

.dashboard_list th {
  color: #000;
  border: 1px solid #0000001a;
  background-color: #f2f8f8;
}

.dashboard_list table,
td {
  border: 1px solid #0000001a;
  border-collapse: collapse;
  color: #6e6b7b;
}

@media (max-width: 1200px) {
  .dashboard_list table,
  td {
    border: 1px solid #0000001a;
    border-collapse: collapse;
    padding: 20px 0 20px 5px !important;
    color: #6e6b7b;
  }
}

.dashboard_list td button a {
  padding: 8px;
  background-color: #56aaaa;
  border: 2px solid #56aaaa;
  border-radius: 5px;
}

.dashboard_list td a {
  color: #56aaaa;
  text-decoration-line: underline !important;
  text-decoration-thickness: 3px !important;
  text-underline-offset: 5px;
}

/******************************************************************* tax accountant  home page end *******************************************************************/

/******************************************************************* tax accountant  view page start *******************************************************************/
.details_heading .card {
  background-color: transparent;
  border: 1px dashed #000;
  border-radius: 10px;
}

.property_btn1 {
  background-color: #fdc011 !important;
  border-color: #fdc011 !important;
}

.property_btn1:hover {
  background-color: #fdc011 !important;
  border-color: #fdc011 !important;
}

/******************************************************************* tax accountant  view page end *******************************************************************/

/******************************************************************* schedule page start *******************************************************************/
/* date picker start */

.qs-datepicker-container {
  background-color: #ffffff;
  border-color: #dee2e6;
}

.qs-datepicker-container .qs-controls {
  background-color: #e9ecef;
}

.qs-datepicker-container .qs-controls .qs-month-year {
  font-weight: 600;
  font-size: 15px;
}

.qs-datepicker-container .qs-controls .qs-month-year:hover {
  border-bottom: 1px solid #e9ecef;
}

.qs-datepicker-container .qs-squares .qs-square {
  font-size: 15px;
  height: 34px;
  width: 34px;
}

.qs-datepicker-container .qs-squares .qs-square:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active):hover,
.qs-datepicker-container .qs-squares .qs-square.qs-current {
  background: #56aaaa;
  /*#2f55d4*/
  color: #ffffff;
  border-radius: 30px;
  font-weight: 500;
  text-decoration: none;
}

.qs-datepicker-container .qs-squares .qs-day {
  font-weight: 500;
  color: #8492a6;
}

/* date picker end */
/******************************************************************* schedule page end *******************************************************************/
/******************************************************************* valuation page start *******************************************************************/
.date_picker input:focus {
  box-shadow: none;
  border: 1px solid #0000001a;
  background-color: #f8f9fc;
}

.date_picker {
  position: relative;
}

.date_picker .filter_date {
  position: absolute;
  right: 20px;
  top: 5px;
}

/******************************************************************* valuation page end *******************************************************************/

.accouant_table {
  height: 100vh;
}

.accouant_table .ant-table {
  overflow: scroll;
  height: auto;
  max-height: 711px;
}

.accouant_table .ant-table::-webkit-scrollbar {
  width: 0px;
}
