.user-edit-profile-picture {
  position: absolute;
  top: 0;
  right: 50px;
  width: 45px !important;
  height: 45px !important;
  border-radius: 50%;
  transform: translate(105%, -35%);
}

.user-remove-profile-picture {
  position: absolute;
  transform: translate(-107%, -35%);
  width: 45px !important;
  height: 45px !important;
}

.tax-accountant-edit-profile-picture {
  position: absolute;
  top: 0;
  right: 50px;
  width: 45px !important;
  height: 45px !important;
  transform: translate(160%, -35%);
}

.tax-accountant-remove-profile-picture {
  position: absolute;
  transform: translate(-52%, -35%);
  width: 45px !important;
  height: 45px !important;
}
