/* @import url('https://fonts.googleapis.com/css2?family=Alatsi&display=swap'); */

/* .toggle_button {
    display: none !important;
}
.toggle_button i {
    line-height: 33px !important;
} */
/* @media (max-width: 1024px) {
    .toggle_button {
        display: block !important;
    }
} */

/* .ant-select-show-arrow {
  width: 100% !important;
} */

.dropdown__toggle {
  border: none !important;
}

.ant-select-show-arrow span {
  color: #6e6b7b !important;
  /* font-weight: 700 !important; */
}

/* Ant Table Css Start */
.ant-table-thead > tr > th {
  position: relative;
  color: #000000d9;
  font-weight: 600;
  font-weight: bold !important;
  text-align: left;
  background: #fafafa;
  background: #f2f8f8 !important;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
  border-right: 1px solid #0000001a !important;
}

.ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 10px !important;
  border-collapse: separate;
  border-spacing: 0;
}

.client_table_parent {
  margin-bottom: 5rem !important;
}

.client_table .ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 0px !important;
  border-collapse: separate;
  border-spacing: 0;
}

.ant-table {
  border-radius: 10px !important;
}

.ant-spin-dot-item {
  background-color: #56aaaa;
}

/* Ant Table Css End */

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #041e421a !important;
  border-radius: 6px !important;
  box-shadow: none !important;
  height: 3.1em !important;
}

.ant-select-selection-placeholder,
.ant-select-selection-item {
  font-weight: normal;
  line-height: 2.4em !important;
}

.ant-form-item-control-input-content,
.ant-form-item-control-input,
.ant-form-item-control {
  padding: 0 !important;
  margin: 0 !important;
}

.ant-picker {
  border-radius: 6px !important;
  height: 43px !important;
  border: 1px solid #dee2e6 !important;
  box-shadow: none !important;
}

.side_wrapper li a {
  font-weight: 600 !important;
  color: #000 !important;
  font-size: 16px !important;
}

.side_wrapper li {
  margin: 0 0 10px 0;
}

/* .page-wrapper .sidebar-wrapper .sidebar-menu {
  padding: 20px 40px !important;
} */

.page-wrapper.landrick-theme .sidebar-wrapper .sidebar-menu li.active {
  background-color: #f2f8f8 !important;
  border-radius: 10px;
  color: #56aaaa !important;
}

.page-wrapper.landrick-theme .sidebar-wrapper .sidebar-menu li.active > a {
  color: #56aaaa !important;
}

.page-wrapper.landrick-theme .sidebar-wrapper .sidebar-menu li.active > a > img {
  filter: brightness(0.9) invert(0.6) sepia(0.6) hue-rotate(132deg) saturate(200%);
}

.page-wrapper.landrick-theme .sidebar-wrapper .sidebar-menu li:hover > a {
  color: none !important;
}

.btn {
  font-size: 16px !important;
  color: #000 !important;
  font-weight: 600 !important;
}

/* form input and select */
label {
  font-size: 16px !important;
}

input::placeholder {
  font-size: 14px !important;
}

select {
  font-size: 14px !important;
}

/* form input and select */

/* body header start */
.property_btn1 {
  background-color: #fdc011 !important;
  border-color: #fdc011 !important;
  padding: 20px 14px !important;
  line-height: 0px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
}

.property_btn1:hover {
  background-color: #fdc011 !important;
  border-color: #fdc011 !important;
  color: #fff;
  padding: 20px 14px !important;
  line-height: 0px;
  border-radius: 6px;
}

.property_btn {
  background-color: #fff !important;
  border-color: #fdc011 !important;
  color: #000;
  padding: 20px !important;
  line-height: 0px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
  margin-right: 15px;
}

.property_btn:hover {
  background-color: #fdc011 !important;
  border-color: #fdc011 !important;
  color: #fff;
}
.property_btn1_icon {
  background-color: #fdc011 !important;
  border-color: #fdc011 !important;
  /* padding: 20px 14px !important; */
  height: 40px;
  line-height: 0px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
}

.property_btn1_icon:hover,
.property_btn1_icon:focus,
.property_btn1_icon:active {
  background-color: #fdc011 !important;
  border-color: #fdc011 !important;
  color: #fff;
  /* padding: 20px 14px !important; */
  line-height: 0px;
  border-radius: 6px;
}

.property_btn1_reload {
  background-color: #fff !important;
  /* border-color: #fff !important; */

  border-color: #0000001a !important;
  /* padding: 20px 14px !important; */
  height: 40px;
  line-height: 0px;
  border-radius: 6px;
  font-weight: 600 !important;
  font-size: 16px;
}

.property_btn1_reload:hover {
  background-color: #fdc011 !important;
  border-color: #fdc011 !important;
  color: #fff !important;
  /* padding: 20px 14px !important; */
  line-height: 0px;
  border-radius: 6px;
}

/* body header end */

/* 1. section start */
.card_wrapper .card {
  border-radius: 10px;
  position: relative;
  border: 1px solid #dfe3e8;
  background: #fff;
  box-shadow: 0px 10px 40px 10px rgba(154, 166, 182, 0.1);
}

.card_wrapper .card .circle {
  position: absolute;
  top: 0;
  right: 0;
}

.card_wrapper .card p {
  font-weight: 500 !important;
}

.pera_icon {
  width: 50px;
  height: 50px;
  background: #e1f3fe;
  text-align: center;
  line-height: 50px;
  border-radius: 5px;
}

.pera_icon1 {
  width: 50px;
  height: 50px;
  background: #e1dbfe;
  text-align: center;
  line-height: 50px;
  border-radius: 5px;
}

.pera_icon2 {
  width: 50px;
  height: 50px;
  background: #fbdadb;
  text-align: center;
  line-height: 50px;
  border-radius: 5px;
}

.pera_icon3 {
  width: 50px;
  height: 50px;
  background: #ddf3e2;
  text-align: center;
  line-height: 50px;
  border-radius: 5px;
}

.pera_icon_property {
  width: 50px;
  height: 50px;
  background: #fff2cf;
  text-align: center;
  line-height: 50px;
  border-radius: 5px;
}

.pera_icon_tax {
  width: 50px;
  height: 50px;
  background: #ffe2d1;
  text-align: center;
  line-height: 50px;
  border-radius: 5px;
}

.pera_icon_onboard {
  width: 50px;
  height: 50px;
  background: #eff7f7;
  text-align: center;
  line-height: 50px;
  border-radius: 5px;
}

.card_wrapper .card .card_ico img {
  /* padding: 12px; */
  color: #43b6fe;
  /* background: #E1F3FE; */
  border-radius: 5px;
}

.card_wrapper .card .card_ico1 img {
  /* padding: 12px; */
  color: #6b49fb;
  /* background: #E1DBFE; */
  border-radius: 5px;
  /* width: 49px; */
}

.card_wrapper .card .card_ico2 img {
  /* padding: 12px; */
  color: #f35c5c;
  /* background: #FBDADB; */
  border-radius: 5px;
}

.card_wrapper .card .card_ico3 img {
  /* padding: 12px; */
  color: #56c36e;
  /* background: #DDF3E2; */
  border-radius: 5px;
}

/* 1. section end */

/* 2. section start */
.action_wrapper .card {
  border-radius: 10px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
  border: none;
}

.action_wrapper .card .action_items {
  padding: 10px 20px;
  border: 1px solid #a7a9ac70;
  border-radius: 5px;
  color: #211e1e;
}

.action_wrapper .card ul {
  padding: 10px 0 10px;
}

/* 2. section end */

/********************************************************************* property page start *********************************************************************/
table {
  border-collapse: collapse;
  border-radius: 10px;
  overflow: visible;
  border: 1px solid #0000001a;
}
.ant-descriptions-view table {
  border: none;
}

.property_list .card {
  border: 1px solid #0000001a;
  border-radius: 11px;
}

.property_list th {
  color: #000;
  /* padding: 5px 0 5px 15px; */
  background-color: #f2f8f8 !important;
}

.property_list table,
td {
  /* border-collapse: collapse; */
  color: #6e6b7b;
}

@media (max-width: 1200px) {
  .property_list table,
  td {
    border: 1px solid #0000001a;
    border-collapse: collapse;
    color: #6e6b7b;
  }

  .transaction_wrapper table,
  td {
    border: 1px solid #0000001a;
    border-collapse: collapse;
    color: #6e6b7b;
  }
}

.property_list td a {
  padding: 8px;
  background-color: #56aaaa;
  border: 2px solid #56aaaa;
  border-radius: 5px;
}

/******** property edit page start ********/
.add_form .card {
  border-radius: 10px;
  height: 885px;
  overflow-y: auto;
}

.audit_form .audit_form .card {
  border-radius: 10px;
  height: 585px;
  overflow-y: auto;
}

/* .add_form .card::-webkit-scrollbar {
  width: 0px;
} */

.add_form .card .card-header {
  background: #f2f8f8;
  padding: 20px 0 20px 25px;
  border-radius: 10px 10px 0 0;
}

.edit_plus a {
  background: #f2f8f8;
  border: 1px solid rgba(4, 30, 66, 0.1);
  color: #000;
  padding: 9px 8px 10px;
  border-radius: 5px;
  line-height: 43px;
}

.edit_plus1 a {
  background: #f2f8f8;
  border: 1px solid rgba(4, 30, 66, 0.1);
  color: #000;
  padding: 0px 8px 0px;
  border-radius: 5px;
  line-height: 43px;
}

.input_ico svg {
  position: absolute;
  top: 55%;
  right: 20px;
}

.bank_btn a {
  color: #56aaaa !important;
  font-size: 20px !important;
  font-weight: 500 !important;
}

.bank_btn1 button {
  color: #fff !important;
}

/******** property edit page end ********/

/******** property view page start ********/
.modal_property .modal-header {
  background-color: #f2f8f8 !important;
}

.modal_property .modal-header h5 {
  font-size: 20px !important;
  font-weight: 600;
}

.modal_property .modal-header button {
  font-weight: 700;
}

.modal_property .modal-body h6 {
  font-weight: 600;
  font-size: 16px;
}

.modal_property .modal-body p {
  font-size: 16px;
  color: #6e6b7b;
}

.modal-dialog {
  max-width: 680px !important;
}

.modal {
  background: rgba(0, 0, 0, 0.8) !important;
}

/******** property view page end ********/

/********************************************************************* property page end *********************************************************************/
/********************************************************************* transaction page start *********************************************************************/
.date_picker select {
  border: 1px solid #dee2e6;
  color: #6c757d;
  border-radius: 6px;
}

.date_picker select:focus {
  box-shadow: none;
  border: 1px solid #dee2e6;
}

.date_picker {
  position: relative;
}

.date_picker .filter_date {
  position: absolute;
  right: 10px;
  top: 5px;
}

.transaction_wrapper .card {
  border: 1px solid #0000001a;
  border-radius: 11px;
}

.transaction_wrapper th {
  color: #000;
  padding: 15px 0 15px 15px;
  border: 1px solid #0000001a;
  background-color: #f2f8f8;
  /* border-radius: 10px 10px 0 0; */
}

.transaction_wrapper table td {
  border: 1px solid #0000001a;
  border-collapse: collapse;
  /* padding: 18px 0 18px 15px; */
  color: #6e6b7b;
}

.transaction_wrapper td a {
  padding: 10px;
  background-color: #56aaaa;
  border-radius: 5px;
}

/********************************************************************* transaction page end *********************************************************************/

/********************************************************************* report page start *********************************************************************/
.report_wrapper .form-switch {
  border: 1px solid #dee2e6;
  padding: 6px 5px 6px 12px !important;
  border-radius: 5px;
}

.report_wrapper input {
  float: right !important;
}

.report_wrapper label {
  font-size: 14px !important;
  font-weight: 400;
  line-height: 26px;
  color: #6e6b7b;
  margin: 0 29px 0 0;
}

/********************************************************************* report page end *********************************************************************/

/********************************************************************* faq page start *********************************************************************/
.accordion-button:not(.collapsed) {
  color: #3c4858;
}

.accordion-button:focus {
  color: #56aaaa !important;
}

.faq_wrapper .card {
  border-radius: 10px;
  border: 1px solid #dfe3e8;
  background: #fff;
  box-shadow: 0px 10px 40px 10px rgba(154, 166, 182, 0.1);
}
.faq_wrapper .card .ant-collapse-content > .ant-collapse-content-box {
  max-height: 100% !important;
  overflow: scroll;
  border-bottom: 1px solid #dcdcdc;
}

.pera_1 {
  max-width: 771px;
  font-size: 16px;
  font-weight: 400;
  color: #6e6b7b;
}

.input_email {
  position: relative;
}

.input_email input {
  background-color: #f2f8f8;
  border: 1px solid #041e421a;
}

.input_email input:focus {
  background-color: #f2f8f8;
  border: 1px solid #041e421a;
}

.input_email a {
  position: absolute;
  top: 3px;
  right: 3px;
  padding: 5px 10px;
  background: #56aaaa;
  color: #fff;
  border-radius: 3px;
}

/********************************************************************* faq page end *********************************************************************/

/********************************************************************* rental summary page start *********************************************************************/
.faq_wrapper1 .card {
  border-radius: 10px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
  /* height:813px; */
  height: 100%;
}

#Iframe-Master-CC-and-Rs {
  max-width: 812px;
  max-height: 100%;
  overflow: hidden;
}

.responsive-wrapper {
  position: relative;
  height: 0;
}

.responsive-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: none;
}

.responsive-wrapper-wxh-572x612 {
  /* padding-bottom: 107%; */
  height: 766px !important;
}

/* .set-box-shadow { 
    -webkit-box-shadow: 4px 4px 14px #4f4f4f;
    -moz-box-shadow: 4px 4px 14px #4f4f4f;
    box-shadow: 2px 2px 12px #4f4f4f;
  } */

.center-block-horiz {
  margin-left: auto !important;
  margin-right: auto !important;
}

.faq_wrapper1 select {
  border: 1px solid #041e421a;
  color: #6e6b7b;
}

.faq_wrapper1 select:focus {
  box-shadow: none;
  border: 1px solid #041e421a;
}

.rental_wrapper1 .card {
  border: 1px dashed #00000059;
  height: 100%;
}

.rental_wrapper .card .card-header {
  background-color: #f2f8f8;
  border-radius: 10px 10px 0 0;
  border-bottom: none;
}

.rental_wrapper .card .card-body p {
  color: #6e6b7b;
}

.rental_wrapper .card .card-body a {
  padding: 3px 8px;
  background: #f2f8f8;
  border-radius: 5px;
  color: #000;
  border: 1px solid #041e421a;
}

/********************************************************************* rental summary page end *********************************************************************/

/********************************************************************* record page start *********************************************************************/
.record_wrapper .card {
  border-radius: 10px;
  height: 800px;
}

.record_wrapper .record_upload .card {
  display: grid;
  justify-content: center;
  align-items: center;
  position: relative;
}
.record_file {
  position: relative;
}
.record_file .del_btn {
  position: absolute !important;
  z-index: 1;
  top: 92%;
  left: 89%;
  color: white;
  background-color: red !important;
}
/* .del_btn:before {
  content: '';
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 60px;
  height: 60px;
  background: #d32525;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
} */
.del_btn:after {
  content: '';
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 55px;
  height: 55px;
  background: #cc5252;
  border-radius: 50%;
  transition: all 200ms;
}
.file-input {
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  padding: 16px !important;
  width: 100% !important;
  height: 300px;
  position: relative !important;
  border-radius: 10px !important;
  background-color: #f2f8f8 !important;
  border: 1px dashed #00000059 !important;
}

@media (max-width: 500px) {
  .file-input {
    padding: 40px 0;
    width: 100%;
    height: 100%;
  }
}

.file-input > [type='file'] {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  opacity: 0 !important;
  z-index: 10 !important;
  cursor: pointer !important;
}

.file-input > .button {
  display: inline-block !important;
  cursor: pointer !important;
  background: #eee !important;
  padding: 8px 16px !important;
  border-radius: 2px !important;
  margin-right: 8px !important;
}

.file-input:hover > .button {
  background: dodgerblue !important;
  color: white !important;
}

.file-input > .label {
  color: #000 !important;
  white-space: nowrap !important;
}

.file-input.-chosen > .label {
  opacity: 1 !important;
}

.record_wrapper .browse_btn {
  border: 1px solid #00000059;
  padding: 7px 12px;
  border-radius: 5px;
}

.record_wrapper select:focus {
  box-shadow: none;
  border: 1px solid #dee2e6;
}

.date_picker .filter_date1 {
  position: absolute;
  top: 35px;
  right: 10px;
}

.save_button {
  color: #fff !important;
}

/********************************************************************* record page end *********************************************************************/

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Input Number Hide Controls */
input[type='number'] {
  -moz-appearance: textfield !important;
}

/* Input Number Hide Controls */

.bg-dash-back {
  background: #fbfbfb !important;
}

/* Edit Button Icon Color and Style */
.ant-btn-icon-only {
  /* background-color: #56aaaa; */
  outline: none !important;
  border-radius: 0.3em;
}

.ant-btn-icon-only img {
  margin: 0 !important;
}

.ant-table-cell .ant-btn-primary {
  width: 2.2em !important;
  height: 2.2em !important;
  border-color: #56aaaa;
}

/* Edit Button Icon Color and Style */

/* Transaction Screen Select Transperaant BG */
.transSelect > .ant-select-selector {
  background-color: transparent !important;
}

/* Transaction Screen Select Transperaant BG */

/* SideBar Font Weight Center */
ul.sidebar-menu li a {
  font-weight: 600 !important;
}

.ant-pagination .ant-pagination-item-active:hover {
  border-color: #56aaaa;
}

/* SideBar Font Weight Center */
.delete_ico_hide .ant-form-item-label label {
  visibility: hidden;
}

/* Antd Pagination Style */
/* .ant-pagination-item-link {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: relative !important;
} */

.anticon .anticon-left {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

/* Antd Pagination Style */

@media (max-width: 1366px) {
  .property_btn1_responsive {
    font-size: 14px !important;
  }
}

.file-input .ant-form-item-row,
.file-input .ant-form-item-control-input,
.file-input .ant-form-item-control-input-content {
  height: 100% !important;
}

.file-input .ant-form-item {
  margin-bottom: 0 !important;
}

/* .ant-upload-list,
.ant-upload-list-text {
  width: 100% !important;
  position: absolute;
  bottom: 10% !important;
  justify-content: space-around !important;
} */

.ant-upload-list .ant-upload-span {
  width: 50% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  margin: auto;
}

.ant-upload-list-item-name {
  width: 30% !important;
  padding: 4px !important;
  border-radius: 5px;
  margin: 0 0 0 5px !important;
}

/* .ant-upload-list-item {
  margin: 0 0 25px !important;
} */

.padding_wrapper {
  padding: 170px 0 80px 0 !important;
}

.padding_section {
  padding: 50px 0 80px 0 !important;
}

.pp_heading {
  color: #2e2e2e;
  font-size: 25px !important;
  font-weight: 600;
}

.padding_section ol li {
  color: #2e2e2e;
  font-size: 18px;
  margin-bottom: 15px;
}

.padding_section ul li {
  color: #2e2e2e;
  font-size: 18px;
  margin-bottom: 15px;
  list-style-type: disc;
}

.sub_point li {
  list-style-type: lower-alpha !important;
}

.padding_section h4 {
  color: #2e2e2e;
  font-size: 20px !important;
  font-weight: 500;
}

.privacy_height {
  height: 500px !important;
}

.list_card {
  border-bottom: 2px solid #80808045 !important;
}

.ant-upload-list-picture-card {
  position: unset !important;
}

.ant-upload-select {
  width: 200px !important;
}

.login_upload .ant-upload-list-picture-card-container {
  width: 200px !important;
}

.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border: 1px solid #d9d9d9 !important;
}

.btn_goBack {
  border: 1px solid #a7a9ac70;
  margin-right: 10px;
  background: transparent;
}
.btn_goBack:hover {
  color: #030b16 !important;
  background: #fff;
}

.ant-tour-title {
  font-size: 16px !important;
}

.ant-spin .ant-spin-dot-item {
  background-color: #56aaaa;
}
.box_shadow {
  border-radius: 10px;
  border: 1px solid #dfe3e8;
  background: #fff;
  box-shadow: 0px 10px 40px 10px rgba(154, 166, 182, 0.1);
}
.table_shadow .ant-table-scroll-horizontal {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.16);
}
.email_btn:hover {
  border-color: #56aaaa !important;
  color: #56aaaa !important;
}
.profile_item {
  cursor: pointer;
}

.property-process-view .ant-btn {
  border-color: #56aaaa !important;
  color: #56aaaa !important;
}
