/* @import url('https://fonts.googleapis.com/css2?family=Alatsi&display=swap'); */

/* .toggle_button {
    display: none !important;
}
.toggle_button i {
    line-height: 33px !important;
}
@media (max-width: 1024px) {
    .toggle_button {
        display: block !important;
    }
} */
.side_wrapper li a {
  /* justify-content: center !important; */
  font-weight: 600 !important;
  color: #000 !important;
  font-size: 16px !important;
}

.side_wrapper li {
  margin: 0 0 10px 0;
}

.btn {
  font-size: 16px !important;
  color: #000 !important;
  font-weight: 600 !important;
}

/* form input and select */
label {
  font-size: 16px !important;
}

input::placeholder {
  font-size: 14px !important;
}

select {
  font-size: 14px !important;
}

/* form input and select */
/******************************************************************* Depreciation Management page start *******************************************************************/
table {
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #0000001a;
}

.dashboard_list .card {
  border: 1px solid #0000001a;
  border-radius: 11px;
}

.dashboard_list th {
  color: #000;
  padding: 15px 0 15px 15px;
  border: 1px solid #0000001a;
  background-color: #f2f8f8;
}

.dashboard_list table,
td {
  border: 1px solid #0000001a;
  border-collapse: collapse;
  /* padding: 15px 0 15px 15px !important; */
  color: #6e6b7b;
}

@media (max-width: 1200px) {
  .dashboard_list table,
  td {
    border: 1px solid #0000001a;
    border-collapse: collapse;
    padding: 20px 0 20px 5px !important;
    color: #6e6b7b;
  }
}

.dashboard_list td a {
  padding: 8px;
  background-color: #56aaaa;
  border: 2px solid #56aaaa;
  border-radius: 5px;
}

/******************************************************************* Depreciation Management page end *******************************************************************/

/******************************************************************* schedule page start *******************************************************************/
.schedule_wrapper .card {
  border-radius: 10px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
  /* height:813px; */
  height: 100%;
}

#Iframe-Master-CC-and-Rs {
  max-width: 812px;
  max-height: 100%;
  overflow: hidden;
}

.responsive-wrapper {
  position: relative;
  height: 0;
}

.responsive-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: none;
}

.responsive-wrapper-wxh-572x612 {
  /* padding-bottom: 107%; */
  height: 766px !important ;
}

.center-block-horiz {
  margin-left: auto !important;
  margin-right: auto !important;
}

.schedule_wrapper select {
  border: 1px solid #041e421a;
  color: #6e6b7b;
  font-size: 14px;
}

.schedule_wrapper select:focus {
  box-shadow: none;
  border: 1px solid #041e421a;
  font-size: 14px;
}

.schedule_wrapper input {
  border: 1px solid #041e421a;
  color: #6e6b7b;
}

.schedule_wrapper input:focus {
  box-shadow: none;
  border: 1px solid #041e421a;
}

.schedule_wrapper .schedule_method input::placeholder {
  background-color: #f2f8f8;
  color: #56aaaa;
}

.schedule_wrapper .schedule_method input {
  background-color: #f2f8f8;
}

.schedule_wrapper .schedule_method input:focus {
  background-color: #f2f8f8;
  border: 1px solid #041e421a;
}

/* date picker start */

.qs-datepicker-container {
  background-color: #ffffff;
  border-color: #dee2e6;
}

.qs-datepicker-container .qs-controls {
  background-color: #e9ecef;
}

.qs-datepicker-container .qs-controls .qs-month-year {
  font-weight: 600;
  font-size: 15px;
}

.qs-datepicker-container .qs-controls .qs-month-year:hover {
  border-bottom: 1px solid #e9ecef;
}

.qs-datepicker-container .qs-squares .qs-square {
  font-size: 15px;
  height: 34px;
  width: 34px;
}

.qs-datepicker-container .qs-squares .qs-square:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active):hover,
.qs-datepicker-container .qs-squares .qs-square.qs-current {
  background: #56aaaa;
  /*#2f55d4*/
  color: #ffffff;
  font-weight: 500;
  text-decoration: none;
  border-radius: 30px;
}

.qs-datepicker-container .qs-squares .qs-day {
  font-weight: 500;
  color: #8492a6;
}

/* date picker end */
/******************************************************************* schedule page end *******************************************************************/

/******************************************************************* Statement page start *******************************************************************/
.statment_border {
  border: 5px solid #f2f8f8;
}

.expense_input input::placeholder {
  font-size: 14px;
}

.expense_input select {
  font-size: 14px;
}

.expense_input .expense_plus a img {
  padding: 7px;
  background-color: #56aaaa;
  color: #fff;
  border-radius: 5px;
}

.btn_delete {
  background-color: transparent !important;
  border: none !important;
}

.btn_submit,
.add_wrapper {
  background-color: #56aaaa !important;
  padding: 5px 20px !important;
  border: 1px solid #56aaaa !important;
  border-radius: 6px !important;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.9px;
}

/* .add_wrapper {

} */
/******************************************************************* Statement page end *******************************************************************/

/******************************************************************* valuation page start *******************************************************************/
.date_picker input:focus {
  box-shadow: none;
  border: 1px solid #0000001a;
  background-color: #f8f9fc;
}

.date_picker {
  position: relative;
}

.date_picker .filter_date {
  position: absolute;
  right: 20px;
  top: 5px;
}

/******************************************************************* valuation page end *******************************************************************/
