.rent-summary-bar-chart {
  height: inherit !important;
}

.bar_chart {
  height: 180px;
}

.custom-rent-height {
  height: 400px;
}
