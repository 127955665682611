/* Coustom for Faq (Antd)  */
/* @import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap'); */

@font-face {
  font-family: 'Nunito';
  src: url('../font/Black.ttf') format('truetype'), url('../font/Black.woff') format('woff'), url('../font/Black.woff2') format('woff2'), url('../font/Black.eot') format('embedded-opentype');
  font-weight: 900;
}
@font-face {
  font-family: 'Nunito';
  src: url('../font/Bold.ttf') format('truetype'), url('../font/Bold.woff') format('woff'), url('../font/Bold.woff2') format('woff2'), url('../font/Bold.eot') format('embedded-opentype');
  font-weight: 700;
}
@font-face {
  font-family: 'Nunito';
  src: url('../font/ExtraBold.ttf') format('truetype'), url('../font/ExtraBold.woff') format('woff'), url('../font/ExtraBold.woff2') format('woff2'),
    url('../font/ExtraBold.eot') format('embedded-opentype');
  font-weight: 800;
}
@font-face {
  font-family: 'Nunito';
  src: url('../font/ExtraLight.ttf') format('truetype'), url('../font/ExtraLight.woff') format('woff'), url('../font/ExtraLight.woff2') format('woff2'),
    url('../font/ExtraLight.eot') format('embedded-opentype');
  font-weight: 200;
}
@font-face {
  font-family: 'Nunito';
  src: url('../font/Light.ttf') format('truetype'), url('../font/Light.woff') format('woff'), url('../font/Light.woff2') format('woff2'), url('../font/Light.eot') format('embedded-opentype');
  font-weight: 300;
}
@font-face {
  font-family: 'Nunito';
  src: url('../font/Medium.ttf') format('truetype'), url('../font/Medium.woff') format('woff'), url('../font/Medium.woff2') format('woff2'), url('../font/Medium.eot') format('embedded-opentype');
  font-weight: 500;
}
@font-face {
  font-family: 'Nunito';
  src: url('../font/Regular.ttf') format('truetype'), url('../font/Regular.woff') format('woff'), url('../font/Regular.woff2') format('woff2'), url('../font/Regular.eot') format('embedded-opentype');
  font-weight: 400;
}
@font-face {
  font-family: 'Nunito';
  src: url('../font/SemiBold.ttf') format('truetype'), url('../font/SemiBold.woff') format('woff'), url('../font/SemiBold.woff2') format('woff2'),
    url('../font/SemiBold.eot') format('embedded-opentype');
  font-weight: 600;
}

.collapse_ant .ant-collapse-header {
  background-color: white !important;
  padding: 0 !important;
}

.collapse_ant .ant-collapse-content-box {
  background-color: white !important;
  /* border: none !important; */
}

.collapse_ant .ant-collapse-content > .ant-collapse-content-box {
  padding: 0% !important;
}

.faq_wrapper .ant-collapse-expand-icon {
  position: absolute !important;
  right: 3% !important;
  color: #3c4858;
  top: 50%;
  z-index: 1000;
}

.send_icon:hover {
  color: white !important;
}

.ant-collapse > .ant-collapse-item {
  border-bottom: none !important;
}

.collapse_ant .ant-collapse-content-box {
  border: 1px solid #eaedfa !important;
  border-radius: 0 0 5px 5px;
}

.ant-collapse-content-active > .accordion-button:before {
  font-size: 10px !important;
  transform: rotate(180deg) !important;
}

/* 
.accordion-button .ant-collapse-header-text {
    color: #fff !important;
} */
/* .acordion-item .ant-collapse-item .ant-collapse-item-active .accordion-header {
    color: aquamarine !important;
} */
/* .card-body .bank_details {
    padding-top: 40px !important;
} */
.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: #56aaaa !important;
  border-color: #56aaaa !important;
}

/* login setup start */
.login_wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/images/bg-login.png');
  /* width: 100vw; */
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.login_wrapper .login_form {
  height: 100%;
  display: grid;
  align-items: center;
}

.signup_wrapper {
  padding: 50px !important;
  overflow-y: scroll !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/images/bg-login.png');
  /* width: 100vw; */
  background-repeat: no-repeat;
  background-size: cover;
}

.login_wrapper .card {
  width: 500px !important;
}

.signup_wrapper .card {
  width: 500px !important;
}

.ant-input > input:hover {
  border: none !important;
  outline: none !important;
}

.ant-input-affix-wrapper > input.ant-input {
  background-color: transparent !important;
  color: #000 !important;
}

.form-icon .ico_img {
  position: absolute;
  top: 13px !important;
  right: 9px !important;
  color: #a7a9ac !important;
  height: 18px;
  width: 18px;
}

.form-icon .login__icon {
  position: absolute;
  top: 31%;
  left: 18px;
}

.modal-content {
  border-radius: 10px !important;
}

.modal-content a {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.btn-close:focus {
  box-shadow: none;
}

.creat_account h2 {
  font-size: 29px;
  font-weight: 700;
}

.creat_account a {
  color: #008080 !important;
  text-decoration: underline !important;
  text-underline-position: under;
  font-size: 16px !important;
}

.creat_account p {
  color: #667080;
  font-weight: 500;
  font-size: 16px;
}

.modal_forgot a {
  color: #77787c;
}

.modal_forgot label {
  color: #77787c !important;
}

.login_button button {
  padding: 8px 60px;
  background-color: #56aaaa;
  border: 1px solid #56aaaa;
  color: #fff;
  border-radius: 10px;
  font-size: 20px;
}
.login_button1 button {
  padding: 8px 50px;
  background-color: #008080;
  border: 1px solid #009797;
  color: #fff;
  border-radius: 10px;
  font-size: 20px;
}

.normal_button button {
  padding: 5px 30px;
  background-color: #56aaaa;
  border: 1px solid #56aaaa;
  color: #fff;
  border-radius: 10px;
  font-size: 16px;
}

.btn_hide {
  border: 1px solid #a7a9ac70;
  padding: 10px 10px;
  border-radius: 10px;
  color: #211e1e;
  display: block;
}

.btn_hide:hover {
  color: #211e1e;
}

/* hide input number arrow */

/* .ant-input:hover {
    border: none !important;
    border-right-width: 1px;
} */

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #d9d9d9 !important;
  border-right-width: 1px;
  z-index: 1;
}

.newInput .input-affix-wrapper-focused {
  border-color: none !important;
  box-shadow: none !important;
  border-right-width: 1px;
  outline: 0;
}

/* login setup end */

.bank_loan {
  border: 1px solid #80808029;
  margin: 0 0 20px 0;
  padding: 17px !important;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0) 0px 2px 8px 0px;
}

/* Delete File Icon In Upload */
.file-upload-custome .ant-upload-list-item-card-actions button {
  opacity: 1 !important;
  padding: 0.8em 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-upload-custome .ant-upload-list-item-card-actions button svg {
  fill: white !important;
}

/* PAGE NOT FOUND COUSTOM  */
.error_logo {
  padding: 100px 20px 0 20px !important;
  justify-content: center;
  text-align: center;
}

/* .ant-modal-mask {
  z-index: 1001 !important;
} */

/* .ant-modal-wrap,
.ant-modal-content {
  z-index: 1003 !important;
} */

.ant-modal-content {
  border-radius: 0.5em !important;
}

input-affix-wrapper:focus {
  z-index: 1;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
  border-right-width: 1px;
  outline: 0;
}

.ant-input-affix-wrapper {
  border-radius: 7px !important;
}

.error_content {
  padding: 40px;
}

.error_text_field {
  justify-content: center;
  text-align: center;
}

.error_text {
  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
  color: #5e5873;
  margin-bottom: 1rem !important;
}

.extra_text {
  /* font-family: Montserrat, Helvetica, Arial, serif;
     */
  font-family: 'Nunito', sans-serif;

  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
}

.error_btn {
  border-color: #56aaaa !important;
  background-color: #56aaaa !important;
  border-radius: 5px !important;
  color: white !important;
  font-weight: 500 !important;
  border: none !important;
  transition: 5s;
  padding: 0px 23px !important;
  box-shadow: 0 1px 5px 1px #56aaaa !important;
  margin-top: 20px;
}

.ant-switch-checked {
  background-color: #56aaaa !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #56aaaa !important;
  border-color: #56aaaa !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #56aaaa !important;
}

.ant-picker-today-btn {
  color: #56aaaa !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #56aaaa !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: #56aaaa !important;
}

/* DETAILS PAGE  */

.details_card {
  border: 1px dashed grey !important;
}

.detail_card_text {
  color: #6e6b7b;
}

.cursor-pointer {
  cursor: pointer;
}

/* Antd Change Form Lable Hastrac To Right From Left */
.ant-form-item-required::before {
  position: absolute;
  right: 0;
}

.ant-form-item-required::after {
  content: '*' !important;
  display: inline-block !important;
  margin-right: 4px !important;
  color: #ff4d4f !important;
  font-size: 14px !important;
  /* font-family: SimSun !important; */
  font-family: 'Nunito', sans-serif;

  line-height: 1 !important;
}

/* Antd Change Form Lable Hastrac To Right From Left */

.extra {
  overflow: auto scroll;
  max-height: 596px;
}

.extra::-webkit-scrollbar {
  width: 0px;
}

.property_btn1:hover {
  color: white !important;
}

.property_btn:hover {
  color: white !important;
}

.property_btn1:focus {
  color: white !important;
}

.ant-select-multiple .ant-select-selection-item {
  height: 35px !important;
}

.ant-btn-primary {
  color: #fff;
  border-color: #56aaaa !important;
  background: #56aaaa !important;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.dropdown__toggle {
  position: relative;
}

.virtual_mail_header span {
  border-radius: 10px;
  background-color: #f2f8f8 !important;
  padding: 10px 13px !important;
  font-size: 16px;
  font-weight: 500;
  color: #212529;
}

.virtual_card {
  /* background-color: rgba(0, 128, 128, 0.1) !important;
     */
  color: #008080 !important;
}

.video_link {
  text-decoration: none !important;
  border: none !important;
  padding: 0 !important;
  color: #6c7782 !important;
}

.video_link:hover {
  color: #008080 !important;
}

.video_content {
  /* margin-top: -70px; */
  padding: 0 !important;
  margin-top: 2em !important;
  margin-bottom: 2em !important;
  margin-left: 0.5em !important;
}

.video_content1 {
  /* margin-top: -70px; */
  padding: 0 !important;
}

.video-container video {
  width: 100%;
  height: 400px;
  /* border: 1px solid #56AAAA; */
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.edit_btn {
  color: white !important;
}

.cancel_btn {
  background-color: #fff !important;
  color: #008080 !important;
  border: 1px solid #009797;
}

.video_main {
  display: contents;
}

.Delete_btn {
  background-color: rgb(238, 125, 125) !important;
  border: none !important;
  color: white;
  /* filter: invert(1); */
}

.documnet_link {
  background: none !important;
  border: none !important;
  color: #008080;
  text-decoration: underline !important;
}

.edit_btn1 {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #cccccc !important;
  border-radius: 4px !important;
  letter-spacing: 1px;
  line-height: 2px !important;
}

.edit_btn1 :hover {
  color: #008080 !important;
}

.Export_csv {
  color: #000 !important;
}

.Export_csv :hover {
  color: #fff !important;
}

.remind_later {
  padding: 10px 10px !important;
  width: 150px !important;
  display: flex;
}

.now_emails {
  padding: 10px 10px !important;
  width: 103px !important;
  display: flex;
}

.copy_btn {
  color: black !important;
  background: #ffc107 !important;
  font-size: 14px !;
  font-weight: 800 !important;
  border-radius: 4px !important;
  border: none !important;
  margin-left: 470px;
  margin-top: 20px;
  position: relative;
}

.copy_btn1 {
  height: auto;
  color: black !important;
  background: #ffc107 !important;
  font-size: 14px !;
  font-weight: 500 !important;
  margin-left: 10px;
  border-radius: 4px !important;
  border: none !important;
  padding: 10px;
}

.search_input {
  position: relative !important;
}

.search_ico {
  position: absolute !important;
  top: 31%;
  right: 14px;
}

.copy_btn3 {
  height: auto;
  color: black !important;
  background: #ffc107 !important;
  font-size: 14px !;
  font-weight: 500 !important;
  margin-left: 10px;
  border-radius: 4px !important;
  border: none !important;
  padding: 10px 25px;
}

.section_modal {
  padding: 10px 0 40px 0 !important;
}

.privacy_height .ant-modal-content {
  height: 680px;
  overflow-y: scroll;
}

.privacy_height .ant-modal-content::-webkit-scrollbar {
  background-color: white;
  color: #009797 !important;
  width: 6px;
}

.privacy_height .ant-modal-content::-webkit-scrollbar-thumb {
  background: #a8a8a8;
}

.txtAccountentModal .card .action_items {
  /* padding: 10px 20px; */
  border: 1px solid #a7a9ac70;
  border-radius: 5px;
  color: white;
  background-color: #009797;
}

.txtAccountentModal .card .plus_button {
  color: black;
  align-items: center !important;
}

/* .txtAccountentModal .ant-modal-body {
  padding: 24px 24px 24px 24px !important;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
} */

.txtAccountentModal .ant-space {
  display: block !important;
}

/* .ant-collapse-expand-icon {
  display: none !important;
} */

.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only .ant-collapse-header-text {
  width: 100% !important;
}

/* .ant-collapse>.ant-collapse-item:last-child,
.ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
    border-bottom: 1px solid #000 !important;
} */
.plus_button svg {
  vertical-align: 1px !important;
}

.ant-collapse-content > .ant-collapse-content-box {
  max-height: 150px !important;
  overflow: scroll;
  border-bottom: 1px solid #dcdcdc;
}

.ant-collapse-content > .ant-collapse-content-box::-webkit-scrollbar {
  width: 10px !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  border-bottom: 1px solid #dcdcdc;
}

/* VIDIO THUMBNAIL  */
.video_content .ytp-large-play-button {
  height: 0px !important;
}

.parant_play {
  position: relative;
}

.play_icon {
  position: absolute;
  left: 55px;
  top: 22px;
  background-color: transparent;
  color: #030b16 !important;
  font-size: 20px;
}

.Image {
  border-radius: 7px;
}

/* MAP ISSUE  */
.pac-container {
  /* width: 10px !important; */
  border: none !important;
}

.txtAccountentModal1 .confirm_btn {
  background: white !important;
  border: 1px solid #56aaaa !important;
}
.txtAccountentModal1 .confirm_btn:hover {
  background: #56aaaa !important;
  border: 1px solid #56aaaa !important;
}

.txtAccountentModal1 .cancle_btn {
  background: #56aaaa !important;
  color: #fff !important;
}

.txtAccountentModal1 .cancle_btn:hover {
  background: #56aaaa !important;
  border: #56aaaa !important;
  color: #fff !important;
}

/* BANK CARD  */

.Bank_card .Connect_btn {
  background-color: #56aaaa;
  border: 1px solid #56aaaa;
  color: white !important;
  font-weight: 600;
  /* margin-top: 115px; */
  margin-right: 5px;
}

.Bank_card .DisConnect_btn {
  border: 1px solid #56aaaa;
  color: #56aaaa !important;
  font-weight: 600;
  justify-content: end !important;
  /* margin-top: 115px; */
}

.Bank_card .DisConnect_btn:hover {
  background-color: #56aaaa !important;
  border: 1px solid #56aaaa !important;
  color: #fff !important;
  font-weight: 600;
}

.Bank_card .Connect_btn:hover {
  background-color: #56aaaa !important;
  border: 1px solid #56aaaa !important;
  color: white !important;
  width: auto;
  font-weight: 600;
  /* margin-top: 115px; */
  margin-right: 5px;
}

/* .btn_connect {
    margin-top: 115px;
    width: 58px !important;
    height: 50px !important;
    border-radius: 8px !important;

}

.btn_disconnect {
    margin-top: 115px;
    width: 59px !important;
    height: 50px !important;
    border-radius: 8px !important;

} */

.Bank_card .pera_icon2 {
  background-color: transparent !important;
  width: 100px;
  margin-top: 32px;
}

.Bank_card .card_ico {
  height: 130px;
  position: relative;
}

.Card_bank .card-text {
  position: absolute;
  top: 133px;
  left: 6px;
}

.Bank_card .account_text {
  font-size: 13px;
  padding: 7px 0px;
  width: auto;
}

.Card_BankName {
  margin-left: 6px;
}

.Bank_card .ant-btn:active {
  background-color: #56aaaa !important;
  border: 1px solid #56aaaa !important;
  color: white !important;
}

.Bank_card .ant-btn:hover,
.DisConnect_btn .ant-btn:focus {
  color: #40a9ff;
  border: 1px solid rgb(209, 202, 202) !important;
  background: #fff;
}

.Bank_card .DisConnect_btn:focus {
  border: 1px solid rgb(209, 202, 202);
}

.Bank_card .Connect_btn:focus {
  text-decoration: none;
  background: #008080 !important;
  border: 1px solid #008080;
}

.btn_connect {
  line-height: 0 !important;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 0px !important;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: #fff;
}

.correct_connect {
  color: white !important;
  background-color: green;
  border-radius: 9px;
  font-size: 18px;
}

.bank_point {
  opacity: 0.5;
  width: 15px;
}

/* .ant-tooltip-inner {
  display: none !important;
} */

.ant-tooltip-arrow {
  bottom: 1px !important;
}

/* TAX UPLOAD  */
.User_import .file-input {
  height: 130px !important;
}

/* DISCONECT MODAL  */
.hr_line {
  width: 455px !important;
}

.Stop_sharing {
  border: none !important;
  background-color: #dde6eb !important;
}

.close_sharing {
  border: none !important;
  background-color: #dde6eb !important;
  padding: 0px 35px !important;
}

/* TAX NEW CSS  */
.del_btn:hover {
  border: 1px solid red !important;
  color: red !important;
  background-color: #fff;
}
.del_btn {
  border: 1px solid red !important;
  color: red !important;
}
.Back_btn:hover {
  color: #030b16 !important;
  background: #fff;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  background-color: #e5e5e5 !important;
  color: #656b72 !important;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  color: #008080 !important;
  background: #fbfbfb !important;
  border: 1px solid #b9babd !important;
  /* border-bottom: none !important; */
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab :hover {
  color: #000 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #009797 !important;
  text-shadow: 0 0 0.25px currentcolor;
}

.Small_text {
  width: 500px !important;
}

/* .ImportUser .ant-upload-list-text-container,
.ImportUser .ant-upload-list-picture-container {
  transition: opacity 0.3s, height 0.3s;
  position: absolute;
  top: -19px;
  left: -54px;
} */

.Failed_Data_Table {
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.Failed_Data_Table::-webkit-scrollbar {
  width: 2px;
  border-radius: 50px;
}

.Failed_Data_Table::-webkit-scrollbar-thumb {
  background: #bcbcbd;
  border-radius: 50px;
}

/* SIGNUP CHANGES  */
.ant-tabs-ink-bar {
  position: absolute;
  background: #56aaaa !important;
  pointer-events: none;
}

.ant-tabs-tab:hover {
  color: #000 !important;
}

.TABS .ant-tabs-tab {
  /* width: 225px !important; */
  text-align: center !important;
}

.TABS .ant-tabs-tab-btn {
  width: 190px !important;
}

.TABS .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
.TABS .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
  display: none !important;
}

.TABS .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 70px !important;
}

/* sign up module changes */

.tandc_wrapper .responsive_wrapper {
  width: 615px !important;
}

.signup_tandc {
  height: 600px !important;
  overflow-y: scroll;
  padding: 0 10px;
}
.signup_tandc h1 {
  padding: 117px 0 0;
}
@media (max-width: 767px) {
  .signup_tandc h1 {
    padding: 0;
  }
}

.signup_tandc::-webkit-scrollbar {
  width: 5px;
}

.signup_tandc::-webkit-scrollbar-thumb {
  background: #56aaaa;
}

.tandc_wrapper .tandc_logo {
  position: sticky;
  top: 0;
  background-color: #fff;
  width: 100%;
  z-index: 999;
}

.tandc_wrapper .tandc_button {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  z-index: 999;
  padding: 30px 0;
}

.add_form .ant-input-affix-wrapper {
  padding: 0px 11px;
}
.browseBtn:hover {
  color: #009797 !important;
  border-color: #56aaaa !important;
  background: #fff;
}
.browseBtn {
  color: #6b6e70;
  border-bottom: 1px solid #6b6e70 !important;
}
.browseBtn1 {
  color: #6b6e70;
}
.browseBtn1:hover {
  color: #6b6e70 !important;
  background: #fff;
}
/* Whats app Fixed icon CSS start  */
.whats_icon {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 35px;
  right: 50px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  text-decoration: none;
}
.whats_icon:hover {
  color: #fff;
  text-decoration: none;
}
.whats_icon i {
  line-height: 50px;
}
.whats_icon:before {
  content: '';
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 60px;
  height: 60px;
  background: #25d366;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}
.whats_icon:after {
  content: '';
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 55px;
  height: 55px;
  background: #25d366;
  border-radius: 50%;
  transition: all 200ms;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
/* Whats app Fixed icon CSS end  */
.react-tel-input .form-control {
  /* width: 470px !important; */
  width: 100% !important;
  padding: 25px 0 25px 47px;
}
.tandc_wrapper .phone_input .react-tel-input .form-control {
  width: 100% !important;
  padding: auto;
}
.phone_input .react-tel-input .form-control {
  width: 245px !important;
  padding: 21px 0 21px 47px;
}
.Image_set {
  overflow-y: scroll !important;
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #56aaaa;
  background-color: #56aaaa;
}
.ant-radio-wrapper:hover .ant-radio-inner {
  border-color: #56aaaa;
}
.ant-pagination .ant-pagination-item-active {
  font-weight: 600;
  background-color: #ffffff;
  border-color: #56aaaa;
}

.help-tooltip-icon {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  cursor: help;
  writing-mode: horizontal-tb;
}

.link-button {
  background-color: transparent;
  border: none;
  display: inline;
  padding: 0;
  color: #008080 !important;
  text-decoration: underline !important;
  text-underline-position: under;
  font-size: 16px !important;
}
