.how-it-works-popover {
  line-height: 2;
}

.how-it-works-popover .ant-tag {
  line-height: inherit !important;
}

.how-it-works-popover .one-time-setup {
  color: #f6676b !important;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1040; /* Below the Popover's overlay */
  pointer-events: none; /* Allow interactions with popover */
}

.popover-content {
  max-width: 550px;
  padding: 16px;
}
