.bank_list_card .ant-card-body {
  height: 100%;
}

.rent-summary-graph .ant-card-body {
  height: 100% !important;
}

.graph-open-button {
  position: absolute;
  right: 23px;
  top: 10px;
  z-index: 1;
}
